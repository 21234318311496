<template>
  <CrudCardTable
    :items="establishments"
    :headers="headers"
    title="Establishments"
    :button-to="{name: 'App-Establishments-New'}"
  >
    <template #item.name="{ item }">
      <router-link :to="{ name: 'App-Establishments-id', params: { id: item.id } }">
        {{ item.name }}
      </router-link>
    </template>
    <template #item.tables="{item}">
      {{ item.tables.length }}
    </template>
  </CrudCardTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'
import { Establishment } from '@/store/models'

export default {
  name: 'EstablishmentsList',
  components: { CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Street', value: 'street' },
        { text: 'City', value: 'city' },
        { text: 'Zip Code', value: 'zipCode' },
        { text: 'Country', value: 'country' },
        { text: 'Legal Identifier', value: 'legalId' },
        { text: 'Number of Tables', value: 'tables' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['activeCompany']),
    establishments () {
      return Establishment.query().with('tables').where('companyId', this.activeCompany.id).get()
    }
  }
}
</script>

<style scoped>

</style>
